import React from "react"
import styled from "styled-components"

import { MainContentMaxWidth } from "components/Layouts/MaxWidth"
import { P, H2, SubCondensed } from "components/Typography"
import Line from "./Line"


const Figure = styled.div`
margin-bottom: 1rem;

img {
	width: 100%;
}
`

const Block = styled.div`
	padding: 1.5rem 1rem;
`

const Links = styled.div`
text-transform: uppercase;
margin-bottom: 2rem;
margin-top: 0.5rem;

`

const Image = ({
	links, image, text, width
}) => {
	return (
		<Block style={{
			width
		}}>
			<Figure>
				<img src={image} alt={text} />
			</Figure>
			<P style={{
				marginBottom: "0",
				fontSize: "1rem"
			}}>{text}</P>
			{links && <Links>
				{links.map(link => <a key={link.url} target="_blank" rel="noopener noreferrer" href={link.url}>
					<SubCondensed style={{
						fontSize: "1.1rem",
						borderBottom: "1px solid",
						display: "inline-block",
						marginRight: "0.5rem"
					}}>
						{link.text}
					</SubCondensed></a>)}
			</Links>}
		</Block>
	)
}

const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`

const ImageRow = ({ title, images, width }) => {
	return (
		<MainContentMaxWidth
			style={{
				marginBottom: "0",
				marginTop: "2rem",
				paddingBottom: "0",
				paddingTop: "1rem"
			}}
		>
			<H2 style={{
				fontSize: "2.8rem",
				textAlign: "center",
				fontWeight: "normal",
				color: "white"
			}}>{title}</H2>

			<Row>
				{images.map((img, i) => <Image width={width} {...img} key={i} />)}
			</Row>

			<Line />
		</MainContentMaxWidth>
	)
}

export default ImageRow