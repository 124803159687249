import React from "react"

import FeaturedTitle from "components/Blog/FeaturedHeader"
import { MainContentMaxWidth } from "components/Layouts/MaxWidth"
import Block from "components/CallToAction/Block";
import ImageRow from "./ImageRow";
import ColorPalette from "./ColorPalette";

const logos = {
	title: "Logos",
	width: "28rem",
	images: [
		{
			image: "/images/mediakit/large-white.png",
			text: "For dark backgrounds use the white color text version",
			links: [
				{ url: "/images/mediakit/logo-white.svg", text: "svg" },
				{ url: "/images/mediakit/logo-white.png", text: "png" }
			]
		},
		{
			image: "/images/mediakit/large-dark.png",
			text: "For light backgrounds use the dark color text version",
			links: [
				{ url: "/images/mediakit/logo-black.svg", text: "svg" },
				{ url: "/images/mediakit/logo-black.png", text: "png" }
			]
		}
	]
}

const logoMarks = {
	title: "Logomark",
	width: "16rem",
	images: [
		{
			image: "/images/mediakit/small-white.png",
			text: "For dark backgrounds use the white color text version",
			links: [
				{ url: "/images/mediakit/logo-white-symbol.svg", text: "svg" },
				{ url: "/images/mediakit/logo-white-symbol.png", text: "png" }
			]
		},
		{
			image: "/images/mediakit/small-dark.png",
			text: "For light backgrounds use the dark color text version",
			links: [
				{ url: "/images/mediakit/logo-black-symbol.svg", text: "svg" },
				{ url: "/images/mediakit/logo-black-symbol.png", text: "png" }
			]
		}
	]
}


const keyArt = {
	title: "KEY ART",
	width: "30rem",
	images: [
		{
			image: "/images/mediakit/sw-keyart-01.jpg",
			text: "Key Art 01",
			links: [
				{ url: "/images/mediakit/sw-keyart-01.jpg", text: "jpg" },
			]
		},
		{
			image: "/images/mediakit/sw-keyart-02.jpg",
			text: "Key Art 02",
			links: [
				{ url: "/images/mediakit/sw-keyart-02.jpg", text: "jpg" },
			]
		}
	]
}



const MediaKit = () => {
	return (
		<div>
			<FeaturedTitle>Media Kit</FeaturedTitle>
			<MainContentMaxWidth>

				<Block title="Download all skyweaver Assets at Once" buttonTitle="Download Skyweaver Press Kit" buttonLink={"https://drive.google.com/drive/folders/15kno28PhmYq03RScSL2XbLgsjEcdMyJj?usp=sharing"} />

				<ImageRow {...logos} />
				<ImageRow {...logoMarks} />
				<ImageRow {...keyArt} />
				<ColorPalette />
			</MainContentMaxWidth>
		</div>
	)
}

export default MediaKit