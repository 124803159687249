import React from "react"
import styled from "styled-components";

import { ButtonLink } from "components/Button"
import { BackgroundImage } from "components/Layouts/Utils"

import { H2, P } from "components/Typography"

const MaxWidth = styled.div`
	max-width: 40rem;
	position: relative;
	z-index: 3;
	margin: auto;
	width: 100%;
`

const Gradient = styled.div`
	position: absolute;
	top: 0;left:0;
	height:100%;
	width:100%;
	background-color: #170D30;
	opacity: 0.4;
	`

const Box = styled.div`
    width: 100%;
    text-align: center;
    padding: 8rem 2rem;
    overflow: hidden;
    position: relative;
		border: 1px solid #705BAB;

    @media (max-width: 768px) {
        padding: 5rem 1rem;
    }
`


const Block = ({
	title, text, buttonTitle, buttonLink, img
}) => {
	return (
		<Box>
			<BackgroundImage src={img ? img : `/images/community/get-started/existing-users.jpg`} />
			<Gradient className="gradient" />
			<MaxWidth className="main-media-content">
				<H2 color="white" style={{
					fontSize: "2.2rem"
				}}>{title}</H2>
				{text && <P>{text}</P>}
				<ButtonLink
					href={buttonLink}
					width="20rem" color="var(--background)" bgColor="white">
					{buttonTitle}
				</ButtonLink>
			</MaxWidth>
		</Box>
	)
}

export default Block