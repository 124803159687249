import React from "react";

import "../../css/main.css";
import "../../css/fonts.css";
import "../../css/reset.css";

import Seo from "../../components/SeoHead";
import Layout from "../../components/Blog/Layout"
import MediaKit from "components/Media/MediaKit";

const MediakitPage = () => {
	return (
		<>
			<Seo />
			<Layout>
				<MediaKit />
			</Layout>
		</>
	);
};


export default MediakitPage
