import React from "react"
import { withPrefix } from "gatsby"
import styled from "styled-components"

const Block = styled.div`
	width: 100%; img {width: 100%}
	padding-top: 2rem;
`

const Line = () => {
	return (
		<Block>
			<img src={withPrefix(`/images/Line.png`)} alt="" />
		</Block>
	)
}

export default Line