import React from "react"
import styled from "styled-components"

import { MainContentMaxWidth } from "components/Layouts/MaxWidth"
import { P, H2 } from "components/Typography"
import Line from "./Line"


const colors = [
	{
		title: "Purple Background",
		hex: "#0C061E",
		border: true
	},
	{
		title: "Dark Purple",
		hex: "#231445",
	},
	{
		title: "Desaturated Purple",
		hex: "#40306B",
	},
	{
		title: "Bright Purple",
		hex: "#AC8FFF",
	},
]



const Color = ({
	title, hex, border
}) => {
	return (
		<ColorElement style={{
			background: hex,
			border: border && "1px solid #444"
		}}>
			<div>
				<P style={{
					marginBottom: "0",
					fontWeight: "bold"
				}}>{title}</P>
				<P style={{
					fontSize: "0.8rem",
					marginBottom: "0"
				}}>{hex}</P>
			</div>

		</ColorElement>
	)
}

const ColorPalette = () => {
	return (
		<MainContentMaxWidth
			style={{
				marginBottom: "0",
				marginTop: "2rem",
				paddingBottom: "0",
				paddingTop: "1rem"
			}}
		>
			<H2 style={{
				fontSize: "2.8rem",
				textAlign: "center",
				fontWeight: "normal",
				color: "white"
			}}>Color Palette</H2>

			<Palette>
				{colors.map(color => <Color {...color} key={color.title} />)}
			</Palette>


			<P style={{
				fontSize: "1rem",
				textAlign: "center"
			}}>When designing materials to feature Skyweaver, use our purple color palette to align with our brand.</P>
			<Line />
		</MainContentMaxWidth>
	)
}

export default ColorPalette




const ColorElement = styled.div`
	height: 120px;
	width: 220px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.4rem;
	margin: 0.5rem;
`

const Palette = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 1rem;
	margin-top: 2rem;
`
